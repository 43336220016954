<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<a
		v-b-toggle="user ? '' : 'auth'"
		href="#"
		class="position-absolute d-flex justify-content-center align-items-center rounded-circle bg-white border shadow"
		style="height:40px; width: 40px; flex-direction: column"
		@click.prevent.stop="favorite(variation)"
	>
		<b-icon
			:icon="variation.favorite ? 'heart-fill' : 'heart'"
			:class="{
				'text-danger': variation.favorite ? true : false
			}"
			style="height: 18px; width: 18px"
		/>
	</a>
</template>

<script>
	export default {
		props: {
			variation: {
				type: Object,
				required: true
			}
		},
		computed: {
			user () {
				return this.$store.state.user
			}
		}
	}
</script>

<style lang="css" scoped>
</style>
