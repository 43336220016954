<template>
	<div id="home">
		<b-row
			class="hero vh-100 align-items-center text-center text-white mb-3"
			:style="{
				backgroundImage: `url(${merchant.settings.website.home_page_bg_img_url || `/images/${merchant.business.type === 'qsr' ? 'restaurant' : merchant.business.type}.jpg`})`,
				boxShadow: 'inset 0 0 0 100vh rgba(0, 0, 0, .4)'
			}"
			no-gutters
		>
			<b-col md="12">
				<img class="mb-4" :src="merchant.settings.general.logo_img_url" height="180px">
				<h5 class="mb-4">
					{{ $t('orderFromFavouriteStore') }}
				</h5>
				<div class="d-flex align-items-center justify-content-center">
					<b-button v-if="location" class="text-capitalize" to="/items" variant="success" size="md">
						<b-icon-bag class="mr-2" />
						{{ $t('getStarted') }}
					</b-button>
					<location
						v-else
						size="lg"
						:classes="['w-50']"
						:placeholder="$t('enterDeliveryLocation')"
						:save="true"
						@location="$router.push('/items')"
					/>
				</div>
			</b-col>
		</b-row>
		<div v-if="merchant.settings.general.show_alert_banner && merchant.settings.general.alert_banner_template" class="container-fluid">
			<b-alert show variant="danger" dismissible>
				<!-- eslint-disable-next-line vue/no-v-html -->
				<div v-html="merchant.settings.general.alert_banner_template" />
			</b-alert>
		</div>
		<div class="container-fluid">
			<nearby-stores class="mb-3" @change="$router.push('/items')" />
		</div>
		<b-carousel v-if="banners.length" class="mb-3" indicators>
			<b-carousel-slide
				v-for="(banner, index) in banners"
				:key="index"
				style="height: 300px"
			>
				<template #img>
					<img
						width="1280"
						height="300"
						:src="banner.file_path"
						:alt="`Banner ${index + 1}`"
						class="d-block m-auto"
						style="max-height: 100%; max-width: 100%"
						@click="onBannerSelected(banner)"
					>
				</template>
			</b-carousel-slide>
		</b-carousel>
		<div class="container-fluid">
			<div v-if="categories.data.length && merchant.settings.app.appearance.home_page.show_categories">
				<h5 class="mb-3">
					{{ $t('shopByCategory') | capitalize }}
				</h5>
				<b-row>
					<b-col
						v-for="(category, index) in categories.data.filter(c => c.id !== null)"
						:key="index"
						md="2"
						class="mb-3"
					>
						<b-card
							class="h-100"
							no-body
							:style="{
								overflow:'hidden',
							}"
							@mouseover="$event.currentTarget.classList.add('shadow')"
							@mouseleave="$event.currentTarget.classList.remove('shadow')"
							@click="selectCategory(category)"
						>
							<div
								class="img-wrap"
								:style="{
									height:'150px',
									width:'100%'
								}"
							>
								<b-img-lazy
									v-bind="{
										blank: true,
										blankColor: '#ccc',
										height: '100%',
										width: '100%',
										objectFit:'contain',
									}"
									:src="category.image_path || '/images/placeholder.svg'"
								/>
								<div
									class="text-overlay"
									:style="{
										opacity:1,
									}"
								>
									{{ category.name }}
									<span v-if="category.alternate_language">
										/ {{ category.alternate_language }}
									</span>
								</div>
							</div>
						</b-card>
					</b-col>
				</b-row>
				<div
					v-if="categories.pagination && categories.pagination.current_page < categories.pagination.next_page"
					v-observe-visibility="(isVisible) => {
						if (isVisible) {
							$store.dispatch('getCategories', {
								page: categories.pagination.next_page
							})
						}
					}"
					class="d-flex justify-content-center my-3"
				>
					<b-spinner :label="$t('loadItems')" />
				</div>
			</div>
			<div
				v-for="(itemBlock, itemBlockKey) in itemBlocks.data"
				:key="itemBlockKey"
			>
				<div v-if="merchant.settings.app.appearance.home_page && merchant.settings.app.appearance.home_page[itemBlockKey] && itemBlock.data.length" class="mb-3">
					<h5
						class="text-capitalize mb-3"
					>
						{{ $t(itemBlock.title.toLowerCase()) }}
					</h5>
					<carousel
						class="item-blocks"
						:per-page="5"
						:pagination-enabled="false"
						:style="{
							paddingLeft: itemBlock.data.length > 4 ? '44px' : 0,
							paddingRight: itemBlock.data.length > 4 ? '50px' : 0
						}"
						navigation-enabled
					>
						<slide v-for="(item, index) in itemBlock.data" :key="index">
							<item-card
								:item="item"
								:class="{
									'mr-3': index + 1 < itemBlock.data.length
								}"
								:controls="false"
							/>
						</slide>
					</carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ItemCard from '@/components/item-card'
	import NearbyStores from '@/components/nearby-stores'

	export default {
		name: 'Home',
		components: {
			ItemCard,
			NearbyStores
		},
		middleware: [
			({ req, store }) => {
				if (req && req.query.referral_code) {
					req.session.referral_code = req.query.referral_code
					store.commit('setState', { key: 'referralCode', value: req.query.referral_code })
				}
			},
			'nearby-store',
			'category'
		],
		asyncData ({ $axios, store }) {
			return $axios.$get('/api/guest/home', {
				params: {
					store_id: store.state.selectedStore ? store.state.selectedStore.id : null
				}
			}).then((response) => {
				return {
					banners: response.data.banners,
					itemBlocks: {
						available: response.data.item_blocks,
						data: {
							show_best_selling_items: {
								title: 'Best Seller',
								data: []
							},
							show_recommended_items: {
								title: 'Recommended',
								data: []
							}
						}
					}
				}
			}).catch(console.error)
		},
		head () {
			return {
				title: `${this.merchant.business.name} | ${this.$options.filters.capitalize(this.$t('home'))}`
			}
		},
		computed: {
			merchant () {
				return this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			categories () {
				return this.$store.state.categories
			},
			selectedStore () {
				return this.$store.state.selectedStore
			}
		},
		mounted () {
			if (this.$route.query.email === 'verified') {
				this.showToast(this.$t('toastMessage.successEmailVerified'), {
					title: `${this.$t('success')}!`
				})
			} else if (this.$route.query.email === 'unverified') {
				this.showToast(this.$t('toastMessage.unableToVerifyEmail'), {
					title: `${this.$t('failed')}!`,
					variant: 'danger'
				})
			}

			this.loadItemBlocks()

			window.onscroll = (e) => {
				const navbar = document.querySelector('nav.navbar.fixed-top')
				const avatar = document.querySelector('nav.navbar.fixed-top .b-avatar')

				if (e.currentTarget.scrollY + 64 > e.currentTarget.innerHeight) {
					navbar.classList.remove('bg-transparent')
					navbar.classList.add('bg-primary')

					if (avatar) {
						avatar.classList.add('badge-light')
						avatar.classList.add('text-primary')
						avatar.classList.remove('text-light')
					}
				} else {
					navbar.classList.remove('bg-primary')
					navbar.classList.add('bg-transparent')

					if (avatar) {
						avatar.classList.remove('badge-light')
						avatar.classList.remove('text-primary')
						avatar.classList.add('text-light')
					}
				}
			}
		},
		destroyed () {
			window.onscroll = () => {}
		},
		methods: {
			loadItemBlocks () {
				if (this.selectedStore) {
					this.itemBlocks.available.forEach((itemBlock) => {
						this.$axios.$get(`/api/guest/home/item-blocks/${itemBlock}`, {
							params: {
								store_id: this.selectedStore.id
							}
						}).then((response) => {
							if (this.itemBlocks.data[itemBlock]) {
								this.itemBlocks.data[itemBlock].data = response.data[itemBlock]
							}
						})
					})
				}
			},
			onBannerSelected (banner) {
				if (this.selectedStore && banner.entity) {
					if (banner.entity.type === 'item') {
						this.$router.push(`/items/${banner.entity.slug}`)
					} else if (banner.entity.type === 'category') {
						this.$router.push({
							path: '/items',
							query: {
								category_id: banner.entity.id
							}
						})
					}
				}
			},
			selectCategory (category) {
				if (this.selectedStore) {
					this.$router.push({
						path: '/items',
						query: {
							category_id: category.id
						}
					})
				} else {
					this.showToast(this.$t('toastMessage.deliveryLocationMandatory'), {
						title: this.$t('alert'),
						variant: 'danger'
					})
				}
			}
		}
	}
</script>

<style lang="scss">
	#home {
		.hero {
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -1;
		}

		.item-blocks .VueCarousel-wrapper {
			padding-left: 6px;
		}
	}
</style>
